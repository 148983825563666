import { Component, inject } from '@angular/core';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MapOptionsSelectableItemComponent } from '../map-options-selectable-item/map-options-selectable-item.component';
import { MapOptionsFollowService } from '../../services/map-options-follow.service';


@Component({
  selector: 'app-map-options-follow',
  standalone: true,
  imports: [FleetManagerComponents, NgbAccordionModule, MapOptionsSelectableItemComponent],
  templateUrl: './map-options-follow.component.html',
  styleUrl: './map-options-follow.component.scss',
})
export class MapOptionsFollowComponent {
  public readonly mapOptionsFollowService = inject(MapOptionsFollowService);

}

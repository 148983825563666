<div class="empty-list--title">
  {{ title }}
</div>

<button
  *ngIf="addButtonText"
  [ngClass]="(themeService.activeTheme$ | async) === 'light' ? 'btn-primary' : 'btn-light'"
  type="button"
  class="btn mt-3"
  (click)="addClicked()"
>
  <svg style="vertical-align: -6px" class="bi pe-none" width="24" height="24">
    <use href="bootstrap-icons.svg#plus" />
  </svg>
  {{ addButtonText }}
</button>

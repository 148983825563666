import { Directive, Input, ElementRef, Renderer2, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from '../services/theme-service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[themedImage]',
  standalone: true,
})
export class ThemedImageDirective implements OnInit, OnDestroy, OnChanges {
  @Input() srcLight: string;
  @Input() srcDark: string;
  @Input() themeReverse = false;
  @Input() themeReverseOnlyDarkMode = false; //Only change the theme when selected (ThemeReverse = true) when the theme is dark
  @Input() isHovering = false;

  private themeSubscription$: Subscription;
  private isDestroyed = false; // Add this flag

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2, private readonly themeService: ThemeService) {}

  ngOnInit() {
    // Subscribe to theme changes
    this.themeSubscription$ = this.themeService.activeTheme$.subscribe((theme) => {
      this.updateSrc(theme);
    });

    // Initialize with the current theme
    this.updateSrc(this.themeService.activeTheme$.value);
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    if (this.themeSubscription$) {
      this.themeSubscription$.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['srcLight'] || changes['srcDark'] || changes['themeReverse'] || changes['themeReverseOnlyDarkMode'] || changes['isHovering']) {
      // Re-apply the image source based on the new inputs
      this.updateSrc(this.themeService.activeTheme$.value);
    }
  }

  private updateSrc(activeTheme: string) {
    if (this.isDestroyed) return;
    const isDarkMode = activeTheme.toUpperCase() === 'DARK';
    const reverse = ((this.themeReverseOnlyDarkMode && isDarkMode) || !this.themeReverseOnlyDarkMode) && (this.themeReverse || this.isHovering);
    const darksrc = !reverse ? this.srcDark : this.srcLight;
    const lightsrc = !reverse ? this.srcLight : this.srcDark;

    const src = isDarkMode ? darksrc : lightsrc;
    this.renderer.setAttribute(this.el.nativeElement, 'src', src);
  }
}

import { effect, Inject, inject, Injectable, InjectionToken, Optional } from '@angular/core';
import L from 'leaflet';
import { MapService } from './map.service';
import { setLayerInteractive } from '../helpers/leaflet-interactive-helper';
import { FleetmanagerApiService } from './fleetmanager-api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { getRegionLeafletObject } from '../app/shared/region-map/region-map.component';

export interface mapLayerInfo {
  name: string;
  isActive: boolean;
  //DO NOT EXPORT THIS TO THE LOCAL STORAGE!
  group: L.LayerGroup;
  allowToggling?: boolean;
}

export interface MapLayerConfig {
  useStorageAsData: boolean; // or any config properties you need
}

export const MAP_LAYER_CONFIG_TOKEN = new InjectionToken<MapLayerConfig>('MAP_LAYER_CONFIG');

@UntilDestroy()
@Injectable()
export class MapLayerService {
  private readonly mapService = inject(MapService);
  private readonly fleetmanagerApiService = inject(FleetmanagerApiService);

  private readonly overlayStorageKey = 'map.overlays';

  public layers: mapLayerInfo[] = [];

  private readonly objectGroupName = 'Objects';
  private readonly regionGroupName = 'Regions';
  private readonly geofencingGroupName = 'Geofencing';
  private readonly openSeaMapGroupName = 'OpenSeaMap';

  public amountLayersDifferentFromDefault = 0;

  public defaultLayers: mapLayerInfo[] = [
    { name: this.openSeaMapGroupName, isActive: true, group: new L.LayerGroup().addLayer(this.mapService.getOpenSeaMapLayer()) },
    { name: this.objectGroupName, isActive: true, group: new L.LayerGroup().setZIndex(900) },
    { name: this.geofencingGroupName, isActive: false, group: new L.LayerGroup().setZIndex(887) },
    { name: this.regionGroupName, isActive: false, group: new L.LayerGroup().setZIndex(888) },
  ];

  private readonly config: MapLayerConfig;

  constructor(@Optional() @Inject(MAP_LAYER_CONFIG_TOKEN) injectedConfig: MapLayerConfig) {
    this.config = injectedConfig || {
      useStorageAsData: true,
    };

    const selectedOverlays = this.config.useStorageAsData ? localStorage.getItem(this.overlayStorageKey) : undefined;
    const overlaysInStorage: mapLayerInfo[] = selectedOverlays ? JSON.parse(selectedOverlays) || [] : [];

    overlaysInStorage.forEach((element: mapLayerInfo) => {
      const defLayer = this.defaultLayers.find((dl) => dl.name === element.name);
      element.group = defLayer?.group ?? new L.LayerGroup();
      this.performActionOnSpecificLayer(element);
    });

    //Add default layers if not included in the overlayInStorage
    this.defaultLayers.forEach((element) => {
      if (!overlaysInStorage.find((o) => o.name === element.name)) {
        //Add openSeaMap layer to the group
        overlaysInStorage.push({ ...element });
      }
    });

    this.layers = overlaysInStorage;
    effect(() => {
      if (this.mapService.mapLoaded()) {
        this.addInitialOverlays();
      }
    });
  }

  public getObjectLayer() {
    return this.layers.find((o) => o.name === this.objectGroupName);
  }

  public objectGroup(): L.LayerGroup | undefined {
    const group = this.getObjectLayer()?.group;
    return group ? (group as L.LayerGroup) : undefined;
  }

  public geofencingGroup(): L.LayerGroup | undefined {
    const group = this.layers.find((o) => o.name === this.geofencingGroupName)?.group;
    return group ? (group as L.LayerGroup) : undefined;
  }

  public regionGroup(): L.LayerGroup | undefined {
    const group = this.layers.find((o) => o.name === this.regionGroupName)?.group;
    return group ? (group as L.LayerGroup) : undefined;
  }

  private addInitialOverlays() {
    if (!this.mapService.Map) return;
    this.layers.forEach((layer) => {
      //Check if this layer is in the storage, if yes: Check to show it or not
      if (layer.isActive) {
        layer.group.addTo(this.mapService.Map!);
      }
    });

    this.calculateDefaultLayers();
  }

  private saveSelectedOverlays() {
    if (!this.config.useStorageAsData) return;
    //We don't want group exported, thats why the mapping is done here
    localStorage.setItem(this.overlayStorageKey, JSON.stringify(this.layers.map((lay) => ({ name: lay.name, isActive: lay.isActive }))));
  }

  public toggleInteractiveOnMarkersOnObjects(enable: boolean) {
    this.objectGroup()
      ?.getLayers()
      .forEach((layer) => {
        if (layer instanceof L.Path || layer instanceof L.Marker) {
          setLayerInteractive(layer, enable);
        }
      });
  }

  public toggleLayer(layer: mapLayerInfo) {
    if (!this.mapService.Map) return;
    layer.isActive = !layer.isActive;

    if (layer.isActive) {
      if (!this.mapService.Map?.hasLayer(layer.group)) layer.group.addTo(this.mapService.Map);

      this.performActionOnSpecificLayer(layer);
    } else if (this.mapService.Map?.hasLayer(layer.group)) layer.group.removeFrom(this.mapService.Map);

    this.saveSelectedOverlays();
    this.calculateDefaultLayers();
  }

  private calculateDefaultLayers() {
    let amountDifferent = 0;
    this.layers.forEach((layer) => {
      this.defaultLayers.forEach((dl) => {
        if (dl.name === layer.name && (layer.allowToggling ?? true)) {
          if (dl.isActive !== layer.isActive) amountDifferent++;
        }
      });
    });
    this.amountLayersDifferentFromDefault = amountDifferent;
  }

  private performActionOnSpecificLayer(layer: mapLayerInfo) {
    // Will have to be enlarged with Vessel types?
    if (layer.name === this.regionGroupName) this.showRegions(layer.group as L.LayerGroup);
  }

  private showGeofencing(layerGroup: L.LayerGroup) {
    if (layerGroup.getLayers().length > 0) {
      return;
    }

    this.fleetmanagerApiService.regions$
      .pipe(
        untilDestroyed(this),
        tap((regions) => {
          regions.forEach((region) => {
            getRegionLeafletObject(region, true)
              ?.bindTooltip(region.name ?? '', { permanent: true, direction: 'top' })
              .addTo(layerGroup);
          });
        })
      )
      .subscribe();
  }

  private showRegions(layerGroup: L.LayerGroup) {
    if (layerGroup.getLayers().length > 0) {
      return;
    }

    this.fleetmanagerApiService.regions$
      .pipe(
        untilDestroyed(this),
        tap((regions) => {
          regions.forEach((region) => {
            getRegionLeafletObject(region, true)
              ?.bindTooltip(region.name ?? '', { permanent: true, direction: 'top' })
              .addTo(layerGroup);
          });
        })
      )
      .subscribe();
  }
}

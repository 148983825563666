<div class="map-options">
  @if(enableSearch){
  <app-vessel-search
    #vesselSearch
    class="map-options--search"
    (selectedItemChange)="mapOptionsService.vesselSearch.set($event)"
    [noBorder]="true"
    [collapseToStart]="true"
    typeAheadPlacement="bottom-end"
  ></app-vessel-search>
  }
  <div class="map-options--sticky">
    <button
      (click)="mapMeasuringService.toggleMeasuringTool()"
      class="map-options--item btn border-0"
      ngbTooltip="Measure"
      placement="left"
      [openDelay]="100"
      triggers="hover"
      [class.map-options--item-active]="mapMeasuringService.measuringActive()"
    >
      <i class="fa-solid fa-ruler-horizontal"></i>
    </button>
    <app-map-options-popup
      class="map-options--item"
      name="Layers"
      fontAwesomeIcon="fa-layer-group"
      [badgeActive]="mapLayerService.amountLayersDifferentFromDefault > 0"
    >
      @for (layer of mapLayerService.layers; track $index) { @if(layer.allowToggling ?? true){
      <div class="dropdown-menu-item prevent-select" ngbDropdownItem (click)="mapLayerService.toggleLayer(layer)">
        {{ layer.name }}

        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" [checked]="layer.isActive" />
        </div>
      </div>
      }}
    </app-map-options-popup>

    @if(enableFilter){
    <app-map-options-popup
      class="map-options--item map-options--item-grouped"
      name="Filter"
      fontAwesomeIcon="fa-filter"
      [badgeActive]="mapOptionsService.filtersActiveCount() > 0"
    >
      <app-map-options-filter></app-map-options-filter>
    </app-map-options-popup>  
  }  
  @if(enableFollow){
    <app-map-options-popup
      class="map-options--item map-options--item-grouped"
      name="Follow"
      fontAwesomeIcon="fa-thumbtack"
      [badgeActive]="mapOptionsFollowService.followingVessels().length > 0"
    >
      <app-map-options-follow></app-map-options-follow>
    </app-map-options-popup>
    }
  </div>
</div>

import { effect, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MapService } from '../../../services/map.service';
import L from 'leaflet';
import { MapLayerService } from '../../../services/map-layer.service';
import { HistoryClient } from '@dotocean/virtualworld-ngx-services';
import { catchError, map, of, tap } from 'rxjs';

export interface FollowVessel {
  id: number;
  name: string;
  imo?: number;
}

@UntilDestroy()
@Injectable()
export class MapOptionsFollowService {
  private readonly mapService = inject(MapService);
  private readonly mapLayerService = inject(MapLayerService);
  private readonly historyClientService = inject(HistoryClient);

  private readonly followingVesselsLocalStorageKey = 'map.follow.vessels';

  public followingVessels: WritableSignal<FollowVessel[]> = signal([]);

  public vesselTrackCoordinates: Record<number, L.LatLng[]> = {};
  public vesselTrackCircle: Record<number, L.Circle> = {};
  public vesselTrack: Record<number, L.Polyline> = {};

  constructor() {
    this.readLocalStorageAndSet();

    effect(() => {
      //Save the navStatus to the local storage
      localStorage.setItem(this.followingVesselsLocalStorageKey, JSON.stringify(this.followingVessels()));
    });

    effect(() => {
      if (this.mapService.mapLoaded()) {
        this.mapService.Map?.on('zoomend', () => {
          Object.values(this.vesselTrackCircle).forEach((vesselTrackCircle) => {
            vesselTrackCircle.setRadius(this.getDynamicRadius());
          });
        });
      }
    });
  }

  public hide(mmsi: number) {
    const vesselTrack = this.vesselTrack[mmsi];
    if (vesselTrack) {
      this.mapLayerService.objectGroup()!.removeLayer(vesselTrack);
    }

    const vesselTrackCircle = this.vesselTrackCircle[mmsi];
    if (vesselTrackCircle) {
      this.mapLayerService.objectGroup()!.removeLayer(vesselTrackCircle);
    }

    delete this.vesselTrack[mmsi];
    delete this.vesselTrackCircle[mmsi];
  }

  public addVesselTrackCoordinates(mmsi: number, lat: number, lng: number, clicked: (lat: number, lng: number) => void) {
    if (!this.isFollowing(mmsi)) return;

    const vesselTrackCoordinates = this.vesselTrackCoordinates[mmsi];
    if (!this.mapService.Map || !vesselTrackCoordinates) return;

    //If the coordinates are the same as last: do not add!
    if (
      !(
        vesselTrackCoordinates.length > 0 &&
        vesselTrackCoordinates[vesselTrackCoordinates.length - 1].lat === lat &&
        vesselTrackCoordinates[vesselTrackCoordinates.length - 1].lng === lng
      )
    ) {
      vesselTrackCoordinates.push(new L.LatLng(lat, lng));
      if (vesselTrackCoordinates.length > 200) vesselTrackCoordinates.shift();
    }

    this.addVesselTrack(mmsi);

    this.showVesselTrackerCircle(mmsi, lat, lng, clicked);
  }

  private showVesselTrackerCircle(mmsi: number, lat: number, lng: number, clicked: (lat: number, lng: number) => void) {
    const vesselTrackCircle = this.vesselTrackCircle[mmsi];
    if (!vesselTrackCircle) {
      this.vesselTrackCircle[mmsi] = L.circle([lat, lng], {
        radius: this.getDynamicRadius(), // Adjust as needed for visual preference
        color: 'orange', // Set the color for the highlight circle
        weight: 3,
        fillOpacity: 0.1,
      })
        .on('click', (e) => {
          clicked(lat, lng);
          L.DomEvent.stopPropagation(e);
        })
        .addTo(this.mapLayerService.objectGroup()!);

      return;
    }
    vesselTrackCircle.setLatLng([lat, lng]);
  }

  private addVesselTrack(mmsi: number) {
    const vesselTrack = this.vesselTrack[mmsi];
    if (!vesselTrack) {
      this.vesselTrack[mmsi] = L.polyline([], {
        color: 'white',
        weight: 1,
        opacity: 0.8,
      }).addTo(this.mapLayerService.objectGroup()!);
      return;
    }
    vesselTrack.setLatLngs(this.vesselTrackCoordinates[mmsi]);
  }

  public toggle(mmsi: number, name: string | undefined, imo: number | undefined) {
    if (!mmsi) return;

    if (this.isFollowing(mmsi)) {
      this.removeFollowingVessel(mmsi);
      return;
    }

    this.addFollowingVessel(mmsi, name, imo);
  }

  private addFollowingVessel(mmsi: number, name: string | undefined, imo: number | undefined) {
    this.vesselTrackCoordinates[mmsi] = [];

    //Get the last 50? Known positions for this vessel and show it in the trackercoordinates for this vessel!
    this.historyClientService
      .getLastKnownLocations(mmsi, 2, undefined)
      .pipe(
        // First, sort the locations by the 'time' property in ascending order
        map((locations) =>
          [...locations].sort((a, b) => {
            // Assuming 'time' is a Date object or a timestamp number
            // If 'time' is a string, you might need to parse it using new Date(a.time)
            return new Date(a.time!).getTime() - new Date(b.time!).getTime();
          })
        ),
        tap((locations) => {
          this.vesselTrackCoordinates[mmsi] = locations.map((location) => new L.LatLng(location.lat!, location.lng!));
          this.addVesselTrack(mmsi);
          // locations.forEach((location) => {
          //   if (!location.lat || !location.lng) return;

          //   this.addVesselTrackCoordinates(mmsi, location.lat, location.lng);
          // });
        }),
        catchError((err) => {
          console.log(err);
          return of(0);
        })
      )
      .subscribe();

    this.followingVessels.set([...this.followingVessels(), { id: mmsi, name: name, imo: imo } as FollowVessel]);
  }

  private removeFollowingVessel(mmsi: number) {
    this.hide(mmsi);

    delete this.vesselTrackCoordinates[mmsi];
    this.followingVessels.set(this.followingVessels().filter((vessel) => vessel.id !== mmsi));
  }

  public isFollowing(mmsi: number | undefined) {
    if (!mmsi) return false;
    if (this.followingVessels().find((vessel) => vessel.id === mmsi)) {
      return true;
    }

    return false;
  }

  private readLocalStorageAndSet() {
    const storedFollowVessels = localStorage.getItem(this.followingVesselsLocalStorageKey);
    if (storedFollowVessels) {
      const followingVessels = JSON.parse(storedFollowVessels) as FollowVessel[];

      followingVessels.forEach((vessel) => {
        this.addFollowingVessel(vessel.id, vessel.name, vessel.imo);
      });
    }
  }

  //TODO: Move to general function?

  private getDynamicRadius(): number {
    // Define the radius adjustment based on zoom level
    const zoomLevel = this.mapService.Map?.getZoom() ?? 13; // Default to 13 if map or zoom is undefined

    // Example radius scaling: adjust the base radius as desired
    const baseRadius = 200; // Base radius at zoom level 13
    const scaleFactor = Math.pow(2, 13 - zoomLevel); // Adjust radius exponentially with zoom

    return baseRadius * scaleFactor;
  }
}

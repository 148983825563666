<div class="map-options--follow">
  @if(mapOptionsFollowService.followingVessels().length > 0){ @for (favoriteVessel of mapOptionsFollowService.followingVessels(); track $index) {
  <div
    class="dropdown-menu-item prevent-select"
    ngbDropdownItem
    (click)="mapOptionsFollowService.toggle(favoriteVessel.id, favoriteVessel.name, favoriteVessel.imo)"
  >
    <div class="map-options--follow--title">
      <span>
        {{ favoriteVessel.id }}
      </span>
      <span>{{ favoriteVessel.name }}</span>
      <span>
        {{ favoriteVessel.imo ?? "-" }}
      </span>
    </div>

    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" [checked]="true" />
    </div>
  </div>
  } }@else {
  <div class="map-options--follow--empty">No vessels followed</div>
  }
  <!--
<div ngbAccordion>
  <div ngbAccordionItem [collapsed]="false">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton type="button" class="btn btn-primary map-options--item-header">
        <span class="flex-fill d-flex justify-content-between text-nowrap text-break">
          My fleet

          <div class="d-flex align-items-center">
            @if(mapOptionsFavoritesService.notActiveCount() > 0){
            <span class="badge bg-danger" (click)="mapOptionsFavoritesService.setAll(true); $event.stopPropagation()">
              {{ mapOptionsFavoritesService.notActiveCount() }}
              <i class="ms-1 fa-solid fa-close"></i>
            </span>
            }

            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="mapOptionsFavoritesService.allSelected()"
                (click)="$event.stopPropagation()"
                (change)="mapOptionsFavoritesService.setAll(!mapOptionsFavoritesService.allSelected())"
              />
            </div>
          </div>
        </span>
      </button>
    </h2>

    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          @for (favoriteVessel of mapOptionsFavoritesService.favoriteVessels(); track $index) {
          <app-map-options-selectable-item
            [value]="favoriteVessel.id"
            [isActive]="favoriteVessel.isActive"
            (toggleClicked)="mapOptionsFavoritesService.toggle(favoriteVessel.id)"
          ></app-map-options-selectable-item>
          }
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem [collapsed]="false">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton type="button" class="btn btn-primary map-options--item-header">
        <span class="flex-fill d-flex justify-content-between">
          Watchlist
          <div class="d-flex align-items-center">
            @if(mapOptionsService.groupedVesselSourcesNotActiveCount() > 0){
            <span class="badge bg-danger" (click)="mapOptionsService.setVesselSourceTypesAll(true); $event.stopPropagation()">
              {{ mapOptionsService.groupedVesselSourcesNotActiveCount() }}
              <i class="ms-1 fa-solid fa-close"></i>
            </span>
            }

            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="mapOptionsService.vesselSourceTypesAllSelected()"
                (click)="$event.stopPropagation()"
                (change)="mapOptionsService.setVesselSourceTypesAll(!mapOptionsService.vesselSourceTypesAllSelected())"
              />
            </div>
          </div>
        </span>
      </button>
    </h2>

    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          @for (group of mapOptionsService.groupedVesselSourceTypes(); track $index) {
          <app-map-options-selectable-item
            [value]="group.name"
            [isActive]="!allAreHidden(group.items)"
            (toggleClicked)="mapOptionsService.toggleVesselSourceByName(group.name, !allAreHidden(group.items))"
          ></app-map-options-selectable-item>
          }
        </ng-template>
      </div>
    </div>
  </div>
</div>-->
</div>

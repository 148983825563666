import { Component, inject } from '@angular/core';
import { MapOptionsSelectableItemComponent } from '../map-options-selectable-item/map-options-selectable-item.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { FleetManagerComponents } from '../../../../shared/fleetmanager-components.module';
import { MapOptionsService, VesselSourceTypeExtended } from '../../services/map-options.service';

@Component({
  selector: 'app-map-options-filter',
  standalone: true,
  imports: [
    FleetManagerComponents,
    NgbAccordionModule,
    MapOptionsSelectableItemComponent,
  ],
  templateUrl: './map-options-filter.component.html',
  styleUrl: './map-options-filter.component.scss',
})
export class MapOptionsFilterComponent {
  public readonly mapOptionsService = inject(MapOptionsService);

  public allAreHidden(items: VesselSourceTypeExtended[]) {
    return items.every((item) => !item.isActive);
  }
}

import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { MapOptionsService } from '../services/map-options.service';
import { FleetManagerComponents } from '../../../shared/fleetmanager-components.module';
import { VesselSearchComponent } from '../../shared/vessel-search/vessel-search.component';
import { MapMeasuringService } from '../services/map-measuring.service';
import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MapLayerService } from '../../../services/map-layer.service';
import { MapOptionsPopupComponent } from './map-options-popup/map-options-popup.component';
import { MapOptionsFilterComponent } from './map-options-filter/map-options-filter.component';
import { MapOptionsFollowService } from '../services/map-options-follow.service';
import { MapOptionsFollowComponent } from './map-options-follow/map-options-follow.component';

@Component({
  selector: 'app-map-options',
  standalone: true,
  imports: [
    FleetManagerComponents,
    VesselSearchComponent,
    NgbDropdownModule,
    MapOptionsPopupComponent,
    NgbAccordionModule,
    MapOptionsFilterComponent,
    MapOptionsFollowComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './map-options.component.html',
  styleUrl: './map-options.component.scss',
})
export class MapOptionsComponent {
  public readonly mapOptionsService = inject(MapOptionsService);
  public readonly mapLayerService = inject(MapLayerService);
  public readonly mapMeasuringService = inject(MapMeasuringService);
  public readonly mapOptionsFollowService = inject(MapOptionsFollowService);

  @Input() public enableSearch = true;
  @Input() public enableFilter = true;
  @Input() public enableFollow = true;
}

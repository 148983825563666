<div ngbAccordion>
  <div ngbAccordionItem>
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton type="button" class="btn btn-primary map-options--item-header">
        <span class="flex-fill d-flex justify-content-between text-nowrap text-break">
          Nav. status

          <div class="d-flex align-items-center">
            @if(mapOptionsService.navigationStatusesNotActiveCount() > 0){
            <span class="badge bg-danger" (click)="mapOptionsService.setNavigationStatusAll(true); $event.stopPropagation()">
              {{ mapOptionsService.navigationStatusesNotActiveCount() }}
              <i class="ms-1 fa-solid fa-close"></i>
            </span>
            }

            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="mapOptionsService.navigationStatusesAllSelected()"
                (click)="$event.stopPropagation()"
                (change)="mapOptionsService.setNavigationStatusAll(!mapOptionsService.navigationStatusesAllSelected())"
              />
            </div>
          </div>
        </span>
      </button>
    </h2>

    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          @for (navigationStatus of mapOptionsService.relevantNavigationStatuses(); track $index) {
          <app-map-options-selectable-item
            [value]="navigationStatus.value"
            [isActive]="navigationStatus.isActive"
            (toggleClicked)="mapOptionsService.toggleNavigationStatus(navigationStatus.code)"
          ></app-map-options-selectable-item>
          }
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem [collapsed]="false">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton type="button" class="btn btn-primary map-options--item-header">
        <span class="flex-fill d-flex justify-content-between">
          Vessel types
          <div class="d-flex align-items-center">
            @if(mapOptionsService.groupedVesselSourcesNotActiveCount() > 0){
            <span class="badge bg-danger" (click)="mapOptionsService.setVesselSourceTypesAll(true); $event.stopPropagation()">
              {{ mapOptionsService.groupedVesselSourcesNotActiveCount() }}
              <i class="ms-1 fa-solid fa-close"></i>
            </span>
            }

            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="mapOptionsService.vesselSourceTypesAllSelected()"
                (click)="$event.stopPropagation()"
                (change)="mapOptionsService.setVesselSourceTypesAll(!mapOptionsService.vesselSourceTypesAllSelected())"
              />
            </div>
          </div>
        </span>
      </button>
    </h2>

    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          @for (group of mapOptionsService.groupedVesselSourceTypes(); track $index) {
          <app-map-options-selectable-item
            [value]="group.name"
            [isActive]="!allAreHidden(group.items)"
            (toggleClicked)="mapOptionsService.toggleVesselSourceByName(group.name, !allAreHidden(group.items))"
          ></app-map-options-selectable-item>
          }
        </ng-template>
      </div>
    </div>
  </div>
</div>
